<template>
  <div class="page">
    <div class="title">Словарь</div>
    <div class="content">
      <button class="close" @click="close">
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
        >
          <path
            d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"
          />
        </svg>
      </button>

      <div class="spacer"></div>

      <div class="label">Название</div>
      <input type="text" class="dict_name" v-model="name" />

      <div class="spacer"></div>

      <div class="label">
        Список вопросов & ответов
        <span class="selector_holder">
          <span
            class="selector"
            :class="{ selected: !showMonaco }"
            @click="showMonaco = false"
            >визуальный</span
          >
          /
          <span
            class="selector"
            :class="{ selected: showMonaco }"
            @click="showMonaco = true"
          >
            json<span v-if="jsonError" class="json_error">
              (есть ошибки)</span
            ></span
          >
        </span>
      </div>
      <br />
      <!-- <textarea class="json_editor" v-model="dictionary"></textarea> -->
      <!-- <button @click="showMonaco = !showMonaco">
        {{ showMonaco ? "Скрыть" : "Показать" }} JSON-редактор
      </button> -->

      <MonacoEditor
        v-if="showMonaco"
        class="editor"
        theme="vs"
        language="json"
        @change="fromStringToJson"
        v-model="dictionary"
      ></MonacoEditor>

      <div class="items" v-if="!showMonaco">
        <div
          class="item"
          v-for="(item, i) of dictionary_json"
          :key="'item' + i"
        >
          <div class="number">#{{ i + 1 }}</div>
          <div class="remove" @click="removeItem(i)">×</div>
          <div class="label">Вопрос</div>
          <input type="text" v-model="item.q" @keyup="fromJsonToString" />
          <br />
          <div class="label">Ответ</div>
          <textarea-autosize
            v-model="item.a"
            height="40"
            @input="fromJsonToString"
          ></textarea-autosize>
        </div>
      </div>
      <button class="addItem btn btn-blue" @click="addItem" v-if="!showMonaco">
        + Добавить ещё
      </button>
      <hr />
      <div class="buttons" style="text-align: right">
        <button @click="close" class="btn btn-red mr-1">Отмена</button>
        <button
          class="btn btn-green"
          @click="save"
          :disabled="
            !name ||
            dictionary_json.length == 0 ||
            jsonError ||
            dictionary_json.find((x) => x.q == '' || x.a == '')
          "
        >
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MonacoEditor from "vue-monaco";

let dictionary_proto = `[
    {
        "q": "", 
        "a": ""
    }
]`;

export default {
  components: {
    MonacoEditor,
  },
  data() {
    return {
      id: null,
      name: "",
      dictionary: dictionary_proto.toString(),
      dictionary_json: JSON.parse(dictionary_proto),
      showMonaco: false,
      jsonError: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.id = null;
      this.name = "";
      this.dictionary = dictionary_proto.toString();
      this.dictionary_json = JSON.parse(dictionary_proto);
    },
    open(dict) {
      if (dict) {
        this.id = Number(dict.id);
        this.name = dict.name.toString();
        this.dictionary = JSON.stringify(dict.dictionary, null, 2);
        this.dictionary_json = JSON.parse(JSON.stringify(dict.dictionary));
      } else {
        this.id = null;
        this.name = "";
        this.dictionary = dictionary_proto.toString();
        this.dictionary_json = JSON.parse(dictionary_proto);
      }
    },
    save() {
      this.$emit("save", {
        id: this.id,
        name: this.name,
        dictionary: this.dictionary,
      });
      this.$emit("close");
    },
    fromStringToJson(newValue) {
      try {
        this.dictionary_json = JSON.parse(newValue);
        this.jsonError = false;
      } catch (e) {
        console.log("wrong json in editor");
        this.jsonError = true;
      }
    },
    fromJsonToString() {
      this.dictionary = JSON.stringify(this.dictionary_json, null, 2);
    },
    addItem() {
      this.dictionary_json.push({
        q: "",
        a: "",
      });
      this.fromJsonToString();
    },
    removeItem(i) {
      this.dictionary_json.splice(i, 1);
      this.fromJsonToString();
    },
  },
};
</script>

<style lang="scss" scoped>
.editor {
  width: 100%;
  height: 500px;

  border: 1px solid #eee;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  position: relative;
  z-index: 2;
}

input,
textarea {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.json_error {
  color: red;
}

.dict_name {
  padding: 5px 10px;
  width: calc(100% - 48px);
  border: unset;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  font-size: 12pt;
}

.items {
  .item {
    position: relative;
    border-left: 1px solid #eee;
    margin-bottom: 10px;
    padding-left: 10px;
    width: calc(100% - 40px);
    .number {
      position: absolute;
      left: -102px;
      font-size: 16pt;
      color: #a9a9a9;
      text-align: right;
      width: 80px;
    }
    .remove {
      position: absolute;
      left: -34px;
      top: 20px;
      font-size: 16pt;
      color: #a9a9a9;
      cursor: pointer;
      &:hover {
        color: #f00;
      }
    }
    &:hover {
      border-color: rgb(55, 0, 255);
      .number {
        color: rgb(55, 0, 255);
      }
    }
    .label {
      cursor: default;
      //   font-weight: bold;
      font-size: 8pt;
      position: relative;
      background: white;
      bottom: -3px;
      width: fit-content;
      padding: 3px 5px;
      border-left: 1px solid;
      border-right: 1px solid;
      border-top: 1px solid;
      border-radius: 4px 4px 0px 0px;
      border-color: #dfdfdf;
      left: 0;
    }
    input {
      width: calc(100% - 20px);
      margin-bottom: 10px;
      padding: 5px 10px;
      border: unset;
      border: 1px solid #dfdfdf;
      border-radius: 0px 4px 4px 4px;
      outline: none;
    }
    textarea {
      width: calc(100% - 20px);
      padding: 5px 10px;
      border: unset;
      border: 1px solid #dfdfdf;
      border-radius: 0px 4px 4px 4px;
      font-family: "Montserrat", sans-serif;
      color: black;
      outline: none;
    }
  }
}

.label {
  margin-bottom: 2px;
  font-size: 12pt;
  cursor: default;
}

.spacer {
  height: 25px;
}

.selector_holder {
  font-size: 9pt;
  .selector {
    font-size: 12pt;
    color: #3f51b5;
    cursor: pointer;
    &.selected {
      text-decoration: underline;
      cursor: default;
    }
  }
}

.buttons {
  text-align: right;
  position: fixed;
  bottom: 0;
  padding: 10px 0px;
  background: #ffffff9c;
  width: 1140px;
  backdrop-filter: blur(5px);
}
</style>