<template>
  <div class="page">
    <div class="title">Вход</div>
    <div class="content">
      <table>
        <tr>
          <td>
            <div class="label">E-mail</div>
            <input type="email" v-model="email" />
          </td>
          <td style="padding-left: 5px">
            <div class="label">Пароль</div>
            <input type="password" v-model="password" />
          </td>
          <td style="vertical-align: bottom; padding-left: 10px">
            <button class="login btn btn-green" @click="login">Войти</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      this.$emit("login", {
        email: this.email,
        password: this.password,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 4px;
  margin-top: 12px;
  font-size: 10pt;
  cursor: default;
}

input {
  padding: 10px;
  background: #ffffff24;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  backdrop-filter: blur(10px);
  border: unset;
}

.login {}

// .login {
//   margin-top: 22px;
//   border: unset;
//   padding: 12px 24px;
//   background: #4caf50;
//   color: white;
//   outline: none;
//   cursor: pointer;
//   &:hover {
//     background: #43a047;
//   }
// }
</style>