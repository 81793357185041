import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize);

import VueTippy from 'vue-tippy'
// import 'vue-tippy/dist/' // optional for styling

Vue.use(VueTippy)

new Vue({
  render: h => h(App),
}).$mount('#app')
