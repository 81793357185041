<template>
  <div class="page">
    <div class="title">Словари</div>
    <div class="content">
      <button class="close" @click="close" v-if="dictionaries.length != 0">
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
        >
          <path
            d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"
          />
        </svg>
      </button>
      <!-- здесь список словарей -->
      <button @click="addDict" class="btn mt-1">+ Создать словарь</button>
      <div class="dicts">
        <div class="add_first" v-if="dictionaries.length == 0">
          Добавь свой первый словарь, чтобы начать работу!
        </div>
        <div
          class="dict"
          v-for="dict in dictionaries"
          :key="'dictlib_' + dict.id"
        >
          <div class="dict_name">
            {{ dict.name }}
            <span class="objectsCount">
              / {{ dict.dictionary.length }}
              {{
                declOfNum(dict.dictionary.length, [
                  "объект",
                  "объекта",
                  "объектов",
                ])
              }}
            </span>
          </div>
          <!-- <div class="dict_length">length: {{ dict.dictionary.length }}</div> -->
          <!-- actions: edit, remove, dublicate  -->
          <div class="dict_actions">
            <button class="btn mr-1" @click="editDict(dict)">
              редактировать
            </button>
            <button class="btn mr-1" @click="dublicateDict(dict)">
              дублировать
            </button>
            <button class="btn btn-red mr-1" @click="removeDict(dict)">
              удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dictionaries"],
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
    addDict() {
      this.$emit("addDictionary");
    },
    editDict(dict) {
      this.$emit("openDictionary", dict);
    },
    removeDict(dict) {
      this.$emit("removeDictionary", dict.id);
    },
    dublicateDict(dict) {
      this.$emit("dublicateDictionary", dict.id);
    },
    declOfNum(number, titles) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.dicts {
  margin-top: 20px;
  .dict {
    margin-bottom: 25px;

    .dict_name {
      margin-bottom: 5px;
      font-size: 14pt;
      cursor: default;
      .objectsCount {
        color: #ddd;
      }
    }
  }
}
</style>