<template>
  <div class="page">
    <div class="title">Распознавание</div>
    <div class="content">
      <div style="height: 25px"></div>
      <div class="detection">
        <Orb2
          class="orb"
          :amplitude="vol"
          :disabled="!micStream || !micStream.recording"
          :done="done"
        />
        <div class="orb-button" @click="startRecordingAndRecognition"></div>
        <div class="text live" v-if="live">
          <template>
            <span style="text-transform: uppercase">{{
              live.slice(0, 1)
            }}</span>
            <span>{{ live.slice(1) }}</span>
          </template>
        </div>
      </div>

      <div>
        <!-- <hr /> -->
        <div class="subtitle">
          Результат поиска по словарю
          <select v-model="local_seleted_dictionary_id" @change="setDictionary">
            <option
              v-for="dict of dictionaries"
              :value="dict.id"
              :key="'dict_' + dict.id"
            >
              {{ dict.name }}
            </option>
          </select>
        </div>
        <template v-if="question">
          <div class="qa_holder">
            <div class="question">{{ question }}</div>
            <div class="answer" v-if="answer">{{ answer }}</div>
          </div>
        </template>
        <template v-else>
          <div class="not_found">Ничего не найдено</div>
        </template>
      </div>

      <div class="old_qa" v-if="old_founds.slice(1).length != 0">
        <div class="subtitle" style="margin-top: 50px">
          Предыдущие результаты
        </div>
        <div
          class="qa_holder"
          v-for="(found, i) of old_founds.slice(1)"
          :key="'old_found_' + i"
        >
          <div class="question">{{ found.q }}</div>
          <div class="answer" v-if="found.a">{{ found.a }}</div>
        </div>
      </div>

      <!-- control panel  -->
      <div class="control_panel">
        <div
          class="item status"
          v-tippy="{
            content: 'Сумма секунд распознавания',
            placement: 'top',
          }"
        >
          {{ Math.ceil(mseconds / 1000) }} сек.
        </div>
        <div
          class="item button"
          v-tippy="{ content: 'Выбрать микрофон', placement: 'top' }"
        >
          <svg
            width="24px"
            height="22px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M13,19.9381062 L13,21 L16,21 L16,23 L8,23 L8,21 L11,21 L11,19.9381062 C7.05368842,19.4460082 4,16.0796177 4,12 L4,10 L6,10 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 L18,10 L20,10 L20,12 C20,16.0796177 16.9463116,19.4460082 13,19.9381062 Z M12,1 C14.209139,1 16,2.790861 16,5 L16,12 C16,14.209139 14.209139,16 12,16 C9.790861,16 8,14.209139 8,12 L8,5 C8,2.790861 9.790861,1 12,1 Z M12,3 C10.8954305,3 10,3.8954305 10,5 L10,12 C10,13.1045695 10.8954305,14 12,14 C13.1045695,14 14,13.1045695 14,12 L14,5 C14,3.8954305 13.1045695,3 12,3 Z"
            />
          </svg>
          <div class="select_list" @mouseenter.prevent.stop @click.prevent.stop>
            <div
              class="select_item"
              v-for="(i_d, i) in input_devices"
              :class="{ selected: local_selected_input_device == i_d.deviceId }"
              :key="i_d.deviceId + '_' + i"
              @mouseenter.prevent.stop
              @click.prevent.stop="micChanged(i_d.deviceId)"
            >
              {{ i_d.label }}
            </div>
          </div>
        </div>
        <div
          class="item button"
          v-tippy="{ content: 'Библиотека словарей', placement: 'top' }"
          @click="openDicts"
        >
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="🔍-System-Icons"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="ic_fluent_dictionary_24_regular"
                fill="#212121"
                fill-rule="nonzero"
              >
                <path
                  d="M18,2 C19.3807,2 20.5,3.11929 20.5,4.5 L20.5,18.75 C20.5,19.1642 20.1642,19.5 19.75,19.5 L5.5,19.5 C5.5,20.0523 5.94772,20.5 6.5,20.5 L19.75,20.5 C20.1642,20.5 20.5,20.8358 20.5,21.25 C20.5,21.6642 20.1642,22 19.75,22 L6.5,22 C5.11929,22 4,20.8807 4,19.5 L4,4.5 C4,3.11929 5.11929,2 6.5,2 L18,2 Z M18,3.5 L6.5,3.5 C5.94772,3.5 5.5,3.94772 5.5,4.5 L5.5,18 L19,18 L19,4.5 C19,3.94772 18.5523,3.5 18,3.5 Z M16,5 C16.5523,5 17,5.44772 17,6 L17,8 C17,8.55228 16.5523,9 16,9 L8,9 C7.44772,9 7,8.55228 7,8 L7,6 C7,5.44772 7.44772,5 8,5 L16,5 Z M15.5,6.5 L8.5,6.5 L8.5,7.5 L15.5,7.5 L15.5,6.5 Z"
                  id="🎨-Color"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <!-- <div>
          Микрофон:
          <select v-model="local_selected_input_device" @change="micChanged">
            <option
              v-for="(i_d, i) in input_devices"
              :key="i_d.deviceId + '_' + i"
              :value="i_d.deviceId"
            >
              {{ i_d.label }}
            </option>
          </select>
        </div>
        <div>
          <button @click="openDicts">Список словарей</button>
        </div>
        <div>
          <div>Секунд потрачено: {{ mseconds / 1000 }} s</div>
        </div> -->

        <!-- <div class="server_status">
          Server {{ connected ? "Connected" : "Disconnected" }}.
        </div> -->
        <!-- <div>
          <button
            @click="start_recognition"
            :disabled="
              !micStream || !micStream.recording || !token || recognition
            "
          >
            start_recognition
          </button>
          <button @click="stop_recognition" :disabled="!recognition">
            stop_recognition
          </button>
          <button @click="start" :disabled="micStream && micStream.recording">
            enable_mic
          </button>
          <button @click="stop" :disabled="!micStream || !micStream.recording">
            disable_mic
          </button>
        </div> -->
        <!-- <div>
          <input
            type="checkbox"
            v-model="auto_restart_recognition"
            @change="AutoRestartRecognitionChanged"
          />
          auto restart recognition
        </div> -->
        <!-- <div>recognition status: {{ recognition ? "work" : "dont" }}</div> -->
        <!-- Словарь:
          <select v-model="local_seleted_dictionary_id" @change="setDictionary">
            <option
              v-for="dict of dictionaries"
              :value="dict.id"
              :key="'dict_' + dict.id"
            >
              {{ dict.name }}
            </option>
          </select> -->
      </div>
    </div>
  </div>
</template>

<script>
import Orb2 from "./Orb2.vue";

export default {
  components: {
    Orb2,
  },
  props: [
    "live",
    "final",
    "question",
    "answer",
    "micStream",
    "token",
    "recognition",
    "selected_input_device",
    "input_devices",
    "vol",
    "seleted_dictionary_id",
    "dictionaries",
    "connected",
    "mseconds",
    "old_founds",
  ],
  data() {
    return {
      auto_restart_recognition: true,
      local_selected_input_device: "",
      local_seleted_dictionary_id: "",
      done: false,
    };
  },
  methods: {
    AutoRestartRecognitionChanged() {
      this.$emit(
        "AutoRestartRecognitionChanged",
        this.auto_restart_recognition
      );
    },
    start_recognition() {
      this.$emit("start_recognition");
    },
    stop_recognition() {
      this.$emit("stop_recognition");
    },
    start() {
      console.log("start");
      this.$emit("start");
    },
    stop() {
      this.$emit("stop");
    },
    micChanged(id) {
      this.local_selected_input_device = id;
      this.$emit("micChanged", this.local_selected_input_device);
    },
    setDictionary() {
      this.$emit("setDictionary", this.local_seleted_dictionary_id);
    },
    openDicts() {
      this.$emit("openDicts");
    },
    startRecordingAndRecognition() {
      console.log("startRecordingAndRecognition");
      if (this.micStream && this.micStream?.recording) {
        this.stop_recognition();
        this.stop();
      } else {
        this.start();
        this.start_recognition();
      }
    },
  },
  mounted() {
    if (this.selected_input_device)
      this.local_selected_input_device = this.selected_input_device.toString();
    if (this.seleted_dictionary_id)
      this.local_seleted_dictionary_id = this.seleted_dictionary_id.toString();
  },
  watch: {
    selected_input_device(new_value) {
      if (new_value) this.local_selected_input_device = new_value.toString();
    },
    seleted_dictionary_id(new_value) {
      if (new_value) this.local_seleted_dictionary_id = new_value.toString();
    },
    final(new_value) {
      console.log("final", new_value);
      this.done = true;
      setTimeout(() => {
        this.done = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
}

.qa_holder {
  .question {
    font-size: 1.5em;
    font-weight: bold;
    color: #3f51b5;
    margin-top: 10px;
  }
  .answer {
    margin-top: -8px;
    font-size: 1em;
    font-weight: bold;
    color: #424242;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    white-space: pre-line;
    padding-left: 16px;
    border-left: 2px solid #e5e5e5;
    padding-top: 15px;
  }
}

.old_qa {
  .qa_holder {
    margin-bottom: 20px;
    .question {
      font-size: 14pt;
    }
    .answer {
      font-size: 12pt;
    }
  }
}

.orb {
  position: relative;
  left: 0px;
}

.orb-button {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  left: 0px;
  top: -40px;
  z-index: 3;
  cursor: pointer;
  &:hover {
    background-color: rgb(0 0 0 / 1%);
  }
}

.detection {
  height: 180px;
}

.vol {
  width: 300px;
  background: #eee;
  overflow: hidden;
  .vol-ind {
    height: 10px;
    background-color: #000;
    transition: 0.1s all;
  }
}

.live {
  position: relative;
  top: -68px;
  left: 23px;
  z-index: 2;
  font-size: 18px;
  font-weight: bold;
  cursor: default;
  background: #ffffff24;
  //   box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  padding: 6px 12px;
  backdrop-filter: blur(10px);
  border-radius: 4px;
  width: fit-content;
  text-align: justify;
  max-width: 600px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  max-height: 106px;
}

.control_panel {
  position: absolute;
  top: -40px;
  right: 0;
  z-index: 3;
  display: flex;

  .item {
    margin-left: 6px;
    margin-right: 6px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: default;

    .select_list {
      display: none;
      animation: none;
    }
    &:hover {
      .select_list {
        z-index: 4;
        display: block;
        position: absolute;
        top: 40px;
        background: #ffffff24;
        box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border-radius: 4px;
        // width: 200px;
        width: calc(((100vw - 1140px) / 2) + 60px);
        max-width: fit-content;
        left: 0;
        padding-top: 10px;
        animation: show_select_list 0.2s ease-in-out;

        .select_item {
          text-overflow: ellipsis;
          width: calc(100% - 16px);
          overflow: hidden;
          word-break: break-all;
          white-space: nowrap;
          padding: 4px 8px;
          cursor: pointer;
          &:hover {
            background: #e5e5e5;
          }
          &.selected {
            background: #3f51b5;
            color: white;
          }
        }
      }
    }
  }

  //   last item
  .item:last-child {
    margin-right: 0;
  }

  .button {
    cursor: pointer;
    border-radius: 4px;
    background: #ffffff24;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    backdrop-filter: blur(10px);
    height: 40px;
    width: 40px;
  }

  .status {
    color: #b5b5b5;
  }
}

@keyframes show_select_list {
  0% {
    opacity: 0;
    top: 30px;
  }
  100% {
    opacity: 1;
    top: 40px;
  }
}

.hidden {
  display: none !important;
}
</style>