<template>
  <div id="app">
    <!-- <div>token:<input type="text" v-model="token" /></div> -->
    <page-mic v-if="states.mic" />
    <page-main v-if="states.main" :connected="connected" :live="live" :final="final" :question="question" :answer="answer"
      :micStream="micStream" :token="token" :recognition="recognition" @start_recognition="start_recognition"
      @stop_recognition="stop_recognition" @start="start" @stop="stop"
      @AutoRestartRecognitionChanged="AutoRestartRecognitionChanged" @micChanged="micChanged"
      :selected_input_device="selected_input_device" :input_devices="input_devices" :vol="vol"
      :dictionaries="dictionaries" :seleted_dictionary_id="seleted_dictionary_id" @setDictionary="setDictionary"
      @openDicts="states.dicts = true" :mseconds="mseconds" :old_founds="old_founds" />
    <page-dicts v-if="states.dicts" @close="closeDicts" :dictionaries="dictionaries" @addDictionary="openDictionary()"
      @openDictionary="openDictionary" @removeDictionary="removeDictionary" @dublicateDictionary="dublicateDictionary" />
    <page-dict ref="page_dict" v-if="states.dict" @close="states.dict = false" @save="saveDictionary" />
    <page-login v-if="states.login" @login="login" />
  </div>
</template>

<script>
import PageMic from "./components/PageMic.vue";
import PageMain from "./components/PageMain.vue";
import PageDict from "./components/PageDict.vue";
import PageDicts from "./components/PageDicts.vue";
import PageLogin from "./components/PageLogin.vue";

const getUserMedia = require("get-user-media-promise");
const MicrophoneStream = require("microphone-stream").default;

function convertFloat32ToInt16(buffer) {
  let l = buffer.length;
  let buf = new Int16Array(l);
  while (l--) {
    buf[l] = Math.min(1, buffer[l]) * 0x7fff;
  }
  return buf.buffer;
}

let io;
io = require("socket.io-client");
let socket_url = document.location.origin.includes("localhost")
  ? "http://localhost:3434"
  : "https://vts-recognizer-server.ilyich.ru";
socket_url = "https://vts-recognizer-server.ilyich.ru";

export default {
  name: "App",
  components: {
    // HelloWorld
    PageMic,
    PageMain,
    PageDict,
    PageDicts,
    PageLogin,
  },
  data() {
    return {
      states: {
        mic: true,
        login: false,
        main: false,
        dicts: false,
        dict: false,
      },

      email: "",
      password: "",
      mseconds: 0,
      token: "",
      input_devices: [],
      selected_input_device: null,
      vol_: 0,
      vol: 0,
      volInterval: null,
      micStream: null,
      live: "Нажми на кнопку,\nчтобы начать распознавание.",
      final: "",
      old_founds: [],
      connected: false,
      recognition: false,
      question: "",
      answer: "",
      auto_restart_recognition: true,
      it_was_auto_restart_recognition: true,
      socket: null,

      dictionaries: [],
      seleted_dictionary_id: 0,

      wave_parts: Array(1024).fill(0),
    };
  },
  async mounted() {
    await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    this.states.mic = false;
    this.states.login = true;
    // 1
    navigator.mediaDevices
      .enumerateDevices()
      .then((data) => {
        this.input_devices = data
          .filter((d) => {
            return d.kind == "audioinput";
          })
          .map((d, i) => {
            return {
              label: d.label || "Input Device " + i,
              deviceId: d.deviceId,
            };
          });

        let local_selected_input_device = localStorage.getItem(
          "selected_input_device"
        );
        if (!local_selected_input_device) {
          this.selected_input_device = this.input_devices[0].deviceId;
          localStorage.setItem(
            "selected_input_device",
            this.selected_input_device
          );
        } else {
          let found = this.input_devices.find(
            (d) => d.deviceId == local_selected_input_device
          );
          if (found) {
            this.selected_input_device = found.deviceId;
          } else {
            this.selected_input_device = this.input_devices[0].deviceId;
            localStorage.setItem(
              "selected_input_device",
              this.selected_input_device
            );
          }
        }
        // console.log("input_devices", this.input_devices);
      })
      .then(() => {
        // 2
        this.volInterval = setInterval(() => {
          this.vol = this.vol_.toFixed(2);
        }, 100);
      })
      .then(() => {
        // 3
        this.socket = io.connect(socket_url);
        this.socket.on("connect", () => {
          console.log("connected");
          this.connected = true;
        });
        this.socket.on("disconnect", () => {
          console.log("disconnected");
          this.connected = false;
          this.states.main = false;
          this.states.dicts = false;
          this.states.dict = false;
          this.states.login = true;
          this.states.mic = false;
        });

        this.socket.on("live", (data) => {
          console.log("live:", data);
          this.live = data.alternative.text;
          if (data.found?.q) this.question = data.found.q;
          if (data.found?.a) this.answer = data.found.a;
        });

        this.socket.on("final", (data) => {
          console.log("final:", data);
          this.live = data.alternative.text;
          this.final = data.alternative.text;
          if (data.found?.q) this.question = data.found.q;
          if (data.found?.a) this.answer = data.found.a;
          if (data.found?.q && data.found?.a) {
            this.old_founds.unshift(data.found);
          }
        });

        this.socket.on("login", (data) => {
          if (data.success) {
            this.token = data.token;
            this.socket.emit("who_i_am", { token: this.token });
            this.states.login = false;
          } else {
            alert("Ошибка входа");
          }
        });

        this.socket.on("you_are", (data) => {
          console.log("you_are:", data);
          this.dictionaries = data.dictionaries;
          this.seleted_dictionary_id = data.current_dictionary.id;
          this.mseconds = data.user.mseconds;

          if (this.dictionaries.length > 0) {
            this.states.main = true;
          } else {
            this.states.dicts = true;
          }
        });

        this.socket.on("dictionaries", (data) => {
          console.log("dictionaries:", data);
          this.dictionaries = data;
          if (data.length == 1) {
            this.seleted_dictionary_id = data[0].id;
            this.socket.emit("select_dictionary", {
              token: this.token,
              dictionary_id: data[0].id,
            });
          }
        });
        this.socket.on("dictionary_set", (data) => {
          if (data.done) {
            console.log("Dictionary set!");
          } else {
            console.error("Dictionary not set!");
          }
        });

        this.socket.on("recognitionStarted", (data) => {
          this.recognition = true;
        });

        this.socket.on("mseconds", (data) => {
          console.log("got mseconds", data);
          this.mseconds = data;
        });

        this.socket.on("recognitionStopped", (data) => {
          this.recognition = false;
          console.log("recognitionStopped", this.auto_restart_recognition);
          if (this.auto_restart_recognition) {
            this.start_recognition();
          }
        });
      });
  },
  destroyed() {
    clearInterval(this.volInterval);
    if (this.micStream) this.micStream.stop();
  },
  methods: {
    start() {
      console.log("start micStream");
      getUserMedia({
        video: false,
        audio: { deviceId: this.selected_input_device },
      })
        .then((stream) => {
          this.micStream = new MicrophoneStream({
            sampleRate: 16000,
            bitRate: 128,
            channels: 1,
            debug: true,
          });
          this.micStream.setStream(stream);
          this.micStream.on("data", (chunk) => {
            const raw = MicrophoneStream.toRaw(chunk);
            let b16int = convertFloat32ToInt16(raw);
            this.vol_ = raw.filter((x) => x >= 0).reduce((a, b) => a + b, 0);

            if (this.recognition) {
              this.socket.emit("audio", {
                token: this.token,
                b16int,
              });
            }
          });
          this.micStream.on("format", function (format) {
            console.log("format", format);
          });
          this.micStream.on("error", function (err) {
            console.log("error", err);
          });
          this.micStream.on("silence", function () {
            console.log("silence");
          });
          console.log("start micStream", this.micStream);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    stop() {
      console.log("stop micStream", this.micStream);
      if (this.micStream) this.micStream.stop();
      this.vol_ = 0;
      this.stop_recognition();
    },
    start_recognition() {
      console.log("start_recognition");
      this.socket.emit("start_recognition", { token: this.token });
      this.auto_restart_recognition = !!this.it_was_auto_restart_recognition;
    },
    stop_recognition() {
      console.log("stop_recognition");
      this.socket.emit("stop_recognition", { token: this.token });
      this.auto_restart_recognition = false;
    },
    login({ email, password }) {
      this.socket.emit("login", {
        email: email,
        password: password,
      });
    },
    setDictionary(id) {
      console.log("setting dict...", id);
      this.socket.emit("set_dictionary", {
        token: this.token,
        id,
      });
    },
    saveDictionary(dict) {
      console.log("command to save dictionary", dict);
      this.socket.emit("save_dictionary", {
        token: this.token,
        dict,
      });
    },
    removeDictionary(id) {
      console.log("command to remove dictionary", id);
      this.socket.emit("remove_dictionary", {
        token: this.token,
        id,
      });
    },
    dublicateDictionary(id) {
      console.log("command to dublicate dictionary", id);
      this.socket.emit("dublicate_dictionary", {
        token: this.token,
        id,
      });
    },
    AutoRestartRecognitionChanged(val) {
      this.auto_restart_recognition = val;
      this.it_was_auto_restart_recognition = !!this.auto_restart_recognition;
    },
    micChanged(mic) {
      this.selected_input_device = mic;
      localStorage.setItem("selected_input_device", mic);
      if (this.micStream) {
        this.stop();
      }
    },
    openDictionary(dict) {
      this.states.dict = true;
      this.$nextTick(() => {
        console.log(this.$refs);
        this.$refs.page_dict.open(dict);
      });
    },
    closeDicts() {
      this.states.dicts = false;
      this.states.main = true;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.tippy-popper {
  font-family: "Montserrat", sans-serif;
}
</style>

<style lang="scss">
.page {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: white;

  .close {
    position: absolute;
    right: 0;
    top: -40px;
    border: unset;
    background: unset;
    cursor: pointer;
    opacity: 0.8;
    color: white;
    fill: white;
    background: black;
    padding: 10px 10px 7px 10px;
    border-radius: 4px;
    z-index: 4;

    &:hover {
      opacity: 1;
    }
  }

  .title {
    font-size: 2em;
    margin: 0 auto;
    margin-bottom: 0px;
    margin-top: 50px;
    width: 1140px;
    position: relative;
    z-index: 3;
  }

  .subtitle {
    font-size: 1.5em;
    margin: 0 auto;
    margin-bottom: 0px;
    margin-top: 10px;
    width: 1140px;
    position: relative;
    z-index: 3;

    select {
      border: unset;
      font-size: 1em;
      font-family: "Montserrat";
      text-decoration: underline;
      outline: none;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";
    }
  }

  .content {
    width: 1140px;
    margin: 0 auto;
    padding-bottom: 50px;
    position: relative;
  }
}

hr {
  border: unset;
  border-top: 1px solid #ddd;
  margin: 30px 0px;
}

.btn {
  padding: 8px 16px;
  border: unset;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  background: #eee;
  color: black;

  &:hover {
    background: #ddd;
  }

  &.btn-green {
    background: #4caf50;
    color: white;

    &:hover {
      background: #43a047;
    }
  }

  &.btn-red {
    background: #e74c3c;
    color: white;

    &:hover {
      background: #c0392b;
    }
  }

  &.btn-blue {
    background: #3498db;
    color: white;

    &:hover {
      background: #2980b9;
    }
  }

  &:disabled {
    background: #eee !important;
    color: #aaa !important;
    cursor: default !important;
  }
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}
</style>