<template>
  <div class="holder" :class="{ disabled, done }">
    <div class="done"></div>
    <div
      class="circle grey"
      :style="{ height: amp0 + 'px', width: amp0 + 'px' }"
    ></div>
    <div
      class="circle red"
      :style="{ height: amp + 'px', width: amp + 'px' }"
    ></div>
    <div
      class="circle blue"
      :style="{ height: amp1 + 'px', width: amp1 + 'px' }"
    ></div>
    <div
      class="circle green"
      :style="{ height: amp2 + 'px', width: amp2 + 'px' }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    amplitude: {
      type: String,
      default: "0",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    done: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      amp0: 50,
      amp: 54,
      amp1: 52,
      amp2: 50,
    };
  },
  mounted: function () {},
  watch: {
    amplitude: function (newVal) {
      this.amp0 = Math.floor((newVal / 500) * 1500) + 50;
      this.amp = Math.floor((newVal / 500) * 600) + 54;
      this.amp1 = Math.floor((newVal / 500) * 400) + 52;
      this.amp2 = Math.floor((newVal / 500) * 200) + 50;
    },
  },
};
</script>

<style lang="scss" scoped>
.holder {
  position: relative;
  top: 10px;
  left: 10px;
  z-index: 2;
  height: 50px;
  width: 50px;
  cursor: pointer;

  .circle {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: 0.1s all;
    animation: skew 2s infinite;
    animation-timing-function: linear;
    transform: translate(-50%, -50%) skew(0deg, 0deg) rotate(0deg);

    &.grey {
      background-color: rgb(245, 245, 245);
      transition: 1s all;
    }
    &.red {
      background: rgba($color: #9900ff, $alpha: 1);
      animation-delay: 0s;
    }
    &.blue {
      background: rgba($color: #3e00a1, $alpha: 1);
      animation-delay: 0.25s;
      transition: 0.3s all;
    }
    &.green {
      background: #fff;
      animation-delay: 0.5s;
      transition: 0.5s all;
    }
  }

  .done {
    background-color: #3e00a1;
    height: 50px;
    width: 10px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1) rotate(45deg);
  }

  &.done {
    .done {
      animation: done 1s infinite;
    }
  }

  &.disabled {
    .circle {
      background-color: #eee;
      animation: unset;
      height: 50px !important;
      width: 50px !important;
    }
  }
}

@keyframes done {
  0% {
    transform: translate(-50%, -50%) scale(1) rotate(45deg);
    width: 10px;
    filter: blur(0px);
    background-color: #3e00a1;
  }
  50% {
    transform: translate(-50%, -50%) scale(5) rotate(-180deg);
    width: 40px;
    filter: blur(0px);
    background-color: #9900ff;
  }
  100% {
    transform: translate(-50%, -50%) scale(1) rotate(-360deg);
    width: 10px;
    filter: blur(0px);
    background-color: #3e00a1;
  }
}

@keyframes skew {
  0% {
    transform: translate(-50%, -50%) skew(0deg, 0deg) rotate(0deg);
  }
  25% {
    transform: translate(-50%, -50%) skew(0deg, -5deg) rotate(90deg);
  }
  50% {
    transform: translate(-50%, -50%) skew(0deg, 0deg) rotate(180deg);
  }
  75% {
    transform: translate(-50%, -50%) skew(0deg, 5deg) rotate(270deg);
  }
  100% {
    transform: translate(-50%, -50%) skew(0deg, 0deg) rotate(360deg);
  }
}
</style>