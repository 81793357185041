<template>
  <div class="page">
    <div class="title">Микрофон</div>
    <div class="content">Пожалуйста, предоставьте доступ к микрофону.</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>